<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item prop="target">
      <el-input v-number-format v-model="scale.target" :disabled="true">
        <template slot="prepend">Цель</template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "card-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    return {
      scale: {
        target: null,
      },

      rules: {}
    }
  },

  methods: {}
}
</script>

<style>

</style>